<template>
  <div class="shift" :style="getStyles(shift.color)" @contextmenu="show" @click="onShiftClick">
    <v-progress-circular v-if="loading" indeterminate color="primary" />
    <v-icon v-else color="white">{{ shift.icon }}</v-icon>
    <v-icon v-if="workplan.locked" size="12" class="lock-icon" color="white">mdi-lock-outline</v-icon>
    <v-menu v-if="!workplan.locked" v-model="showMenu" :position-x="x" :position-y="y" absolute offset-y>
      <v-list dense class="pa-0">
        <v-list-item @click="onDeleteClick">
          <v-list-item-icon class="mr-2">
            <v-icon size="16">mdi-delete</v-icon>
          </v-list-item-icon>
          <v-list-item-title>
            {{ $t("buttons.delete") }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
  export default {
    name: "ShiftItem",
    props: {
      shift: Object,
      workplan: Object
    },
    data: () => ({
      showMenu: false,
      x: 0,
      y: 0,
      loading: false
    }),
    methods: {
      onShiftClick() {
        this.$eventBus.$emit("open:shift-detail", this.shift, this.workplan);
      },
      onDeleteClick() {
        this.loading = true;
        this.$api.workplanService
          .remove(this.workplan.id)
          .then(response => {
            if (response.status === 204) {
              this.$eventBus.$emit("delete:workplan", this.workplan.id, this.workplan.employee.id);
            }
          })
          .catch(error => this.$helpers.showNotification(error.message))
          .finally(() => (this.loading = false));
      },
      show(e) {
        e.preventDefault();
        this.showMenu = false;
        this.x = e.clientX;
        this.y = e.clientY;
        this.$nextTick(() => {
          this.showMenu = true;
        });
      },
      getStyles(color) {
        return {
          backgroundColor: color
        };
      }
    }
  };
</script>

<style lang="scss" scoped>
  .shift {
    cursor: pointer;
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    &.other:not(:last-child) {
      margin-bottom: 10px;
    }
    .lock-icon {
      position: absolute;
      top: 1px;
      left: 1px;
    }
  }
</style>
